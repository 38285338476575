import { useCallback } from "react";
import { useEventListener, useToggle } from "@wojtekmaj/react-hooks";

import { appConfig, demystConsoleApiUrls, blackfinApiUrls } from "../../config/appConfig";
import { Select } from "../components/formComponents";
import { Button } from "../components/buttons/Button";
import { setBlackfinApiUrl, setDemystConsoleApiUrl } from "../../api/api";
import dynamic from "next/dynamic";
import { Paper } from "../components/Paper/Paper";
import { DevToolsSelect } from "./DevToolsSelect";

const DevToolsComponent = () => {
  const [showDevTools, toggleShowDevTools] = useToggle();

  const onDevtoolsShortcutPress = useCallback(event => {
    if (
      (event.ctrlKey && event.key === appConfig.DEVTOOLS_SHORTCUT_KEY) ||
      (event.ctrlKey && event.code === `Key${appConfig.DEVTOOLS_SHORTCUT_KEY.toUpperCase()}`)
    ) {
      toggleShowDevTools();
    }
  }, []);

  useEventListener(document, "keypress", onDevtoolsShortcutPress);

  const onLocalDemystConsoleApiUrlChange = apiAddress => {
    setDemystConsoleApiUrl(apiAddress);
  };

  const onLocalBlackfinApiUrlChange = apiAddress => {
    setBlackfinApiUrl(apiAddress);
  };

  // const onLocalVariantChange = (cookieName, variant) => {
  //   window.localStorage.setItem(cookieName, variant);
  //   document.cookie = `${cookieName}=${variant}`;
  // };

  const reloadPage = () => {
    window.location.reload(false);
  };

  if (!showDevTools) {
    return null;
  }

  return (
    <div className="DevTools">
      <Paper className="DevTools__paper">
        <section className="DevTools__paper__table">
          <div className="DevTools__paper__column">
            <p className="DevTools__paper__column__title">Environment Settings</p>
            <Select
              label={"Demyst Console API URL"}
              size={"sm"}
              defaultValue={appConfig.DEMYST_CONSOLE_API_URL}
              onChange={event => onLocalDemystConsoleApiUrlChange(event.target.value)}
            >
              {Object.keys(demystConsoleApiUrls).map(envName => (
                <option key={envName} value={demystConsoleApiUrls[envName]}>
                  {envName}
                </option>
              ))}
            </Select>
            <Select
              label={"Blackfin API URL"}
              size={"sm"}
              defaultValue={appConfig.BLACKFIN_API_URL}
              onChange={event => onLocalBlackfinApiUrlChange(event.target.value)}
            >
              {Object.keys(blackfinApiUrls).map(envName => (
                <option key={envName} value={blackfinApiUrls[envName]}>
                  {envName}
                </option>
              ))}
            </Select>
          </div>
          <div className="DevTools__paper__column">
            <p className="DevTools__paper__column__title">Nav Pages</p>
            <DevToolsSelect label={"Projects"} lsValueName={"projects_visible"} />
            <DevToolsSelect label={"Internal Data"} lsValueName={"internal_data_visible"} />
            <DevToolsSelect label={"Reports Monitor"} lsValueName={"reports_monitor"} />
            <DevToolsSelect label={"New Homepage"} lsValueName={"new_homepage"} />
            <DevToolsSelect
              label={"Table Provider Exports"}
              lsValueName={"table_provider_exports"}
            />
            <DevToolsSelect
              label={"Table Provider Imports"}
              lsValueName={"table_provider_imports"}
            />
          </div>
          <div className="DevTools__paper__column">
            <p className="DevTools__paper__column__title">Features</p>
            <DevToolsSelect
              label={"User Tracking in Browser Console"}
              lsValueName={"user_tracking_visible"}
            />
            <DevToolsSelect label={"Checklist on Admin"} lsValueName={"checklist"} />
            <DevToolsSelect
              label={"Contracts on Billing"}
              lsValueName={"contracts_on_billing_visible"}
            />
            <DevToolsSelect label={"Attributes"} lsValueName={"attributes"} />
            <DevToolsSelect
              label={"Global Catalog Statistics"}
              lsValueName={"global_catalog_statistics"}
            />
            <DevToolsSelect
              label={"Data Utilization Sans Graphs"}
              lsValueName={"data_utilization_sans_graphs"}
            />
          </div>
        </section>
        <section className="DevTools__paper__actions">
          <Button onClick={reloadPage}>Reload</Button>
          <Button variant={"transparent"} onClick={() => toggleShowDevTools()}>
            Close
          </Button>
        </section>
      </Paper>
    </div>
  );
};

DevToolsComponent.propTypes = {};

export const DevTools = dynamic(async () => DevToolsComponent, {
  ssr: false,
});
