import { PropTypes } from "prop-types";
import clsx from "clsx";

export const Paper = ({
  arrowClassName,
  arrowPlacement = "top",
  borderRadius,
  children,
  className,
  elevation = 0,
  showArrow = false,
  showBorder = true,
  semanticElement = "div",
}) => {
  const SemanticElementToRender = semanticElement;
  return (
    <SemanticElementToRender
      className={clsx(
        "Paper",
        `Paper__elevation--${elevation}`,
        borderRadius && `Paper__borderRadius--${borderRadius}`,
        showBorder && "Paper--withBorder",
        className,
      )}
    >
      {showArrow && (
        <div className={clsx("Paper__arrow", `Paper__arrow--${arrowPlacement}`, arrowClassName)} />
      )}
      {children}
    </SemanticElementToRender>
  );
};

Paper.propTypes = {
  arrowClassName: PropTypes.string,
  arrowPlacement: PropTypes.oneOf(["top", "bottom", "left", "right", "top-right"]),
  borderRadius: PropTypes.oneOf(["xs", "sm", "md", "lg"]),
  children: PropTypes.node,
  className: PropTypes.string,
  elevation: PropTypes.oneOf([1, 2]),
  showArrow: PropTypes.bool,
  showBorder: PropTypes.bool,
};
