import PropTypes from "prop-types";
import { useEffect } from "react";
import { useState } from "react";
import { Select } from "../components/formComponents";

export const DevToolsSelect = ({ label, lsValueName, trueLabel = "Show", falseLabel = "Hide" }) => {
  const [selectValue, setSelectValue] = useState("false");
  const defaultValue = window.localStorage.getItem(lsValueName) || false;

  const onSelectChange = (value, key) => {
    localStorage.setItem(key, value);
    setSelectValue(value);
  };

  useEffect(() => {
    setSelectValue(defaultValue);
  }, [lsValueName]);

  return (
    <Select
      label={label}
      size={"sm"}
      defaultValue={defaultValue}
      onChange={event => onSelectChange(event.target.value, lsValueName)}
      inputClassName={
        selectValue === "true" ? "DevTools__paper__item--true" : "DevTools__paper__item--false"
      }
    >
      <option value="false">{falseLabel}</option>
      <option value="true">{trueLabel}</option>
    </Select>
  );
};

DevToolsSelect.propTypes = {
  falseLabel: PropTypes.string,
  label: PropTypes.string,
  lsValueName: PropTypes.string,
  trueLabel: PropTypes.string,
};
